
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
} 

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.overallbg {
  background-color: rgba(255, 255, 255, 0.817) !important;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.95),
    rgba(255, 255, 255, 0.9)
  ),
  url(./images/ohd-logo-transparant.png);
  background-size:contain;
  min-height: 98vh;
  font-family: mil;
}

.overallbg_dark{
  background-color: rgba(255, 255, 255, 0.817) !important;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  ),
  url(./images/greybackdrop.png);
  background-size:contain;
  font-family: mil;
  min-height: 99vh;
}

.inputSearch {
  background-color: white;
  box-sizing: border-box;
  box-shadow: 0 0 10px grey;

}

.inputSearch_dark {
  background-color: black !important;
  color: gold !important;
}

input[type=search-light]:focus{
  color: black !important;
  box-shadow: 0 0 10px grey;
}


input[type=search-dark]{
  border: 1px solid gold;
}

input[type=search-dark]:focus{
  box-shadow: 0 0 10px  gold;
  border: 1px solid gold;
}

.ohdbg {
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.5),
      rgba(9, 1, 1, 0.817)
    ),
    url(./images/ohdbackdrop2.jpeg);
  background-size: cover;
  min-height: 100vh;
  font-family: mil;
  position: relative;
  border: 1px solid black;
}

.ohdbg2 {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.1)
    ),
    url(./images/DesertWarzone.png);
  background-size: cover;
  min-height: 100vh;
  font-family: mil;
  position: relative;
  border: 1px solid rgb(144, 133, 133);
}

.ohdbg3 {
  background-size: cover;
  min-height: 100vh;
  font-family: mil;
  border: 1px solid black;

  background: linear-gradient(180deg, #000000, #0000007c);
}

.ohdbg5 {
  background-size: cover;
  min-height: 100vh;
  font-family: mil;
  border: 1px solid black;

  background: linear-gradient(-45deg, #005e11d5, #005bc984);
  background-size: 400% 400%;
  -webkit-animation: Gradient 15s ease infinite;
  -moz-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite;
}

.ohdbg6 {
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.7)
    ),
    url(./images/DesertWarzone.png);
    background-size: cover;
    min-height: 100vh;
    font-family: mil;
    border: 1px solid black;
    color: white;
}

.ohdbg7 {
    background: linear-gradient(180deg, #000000, #0000007c);
    min-height: 100vh;
    font-family: mil;
    border: 1px solid black;
    color: white;
}

.ohdbg8 {
  background-size: cover;
  min-height: 100vh;
  border: 1px solid black;
  color:rgba(255, 255, 255, 0.9);
  background: linear-gradient(180deg, #000000, #0000007c);
}

.ohdbg_discord {
  background-size: cover;
  min-height: 100vh;
  border: 1px solid black;
  color:rgba(0, 0, 0, 1);
  background: linear-gradient(0deg, #5865F2, #FFFFFF);
}

.ohdbg_serversetup {
  color: white;
  background-size:contain;
  background-color: black;
  background-repeat: no-repeat;
  border: 1px solid black;
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    ),
    url(./images/ohd_gun_logo.png);
}

.ohdbg_serverresults {
  background-size:contain;
  background-color: black;
  background-repeat: no-repeat;
  min-height: 100vh;
  font-family: mil;
  border: 1px solid black;
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.7)
    ),
    url(./images/ohd_gun_logo.png);
}

.ohdbg_serverinfo {
  background-size:cover;
  min-height: 100vh;
  font-family: mil;
  border: 1px solid black;
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.7)
    ),
    url(./images/ohd_gun_logo.png);
}

.ohdbg_about {
  color: white;
  background-size:contain;
  background-color: black;
  background-repeat: no-repeat;
  min-height: 100vh;
  border: 1px solid black;
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.8)
    ),
    url(./images/ohd_gun_logo.png);
}

@media (prefers-reduced-motion: no-preference) {
  .ohdbg4 {
    animation: background-animation infinite 30s ease-in;
  }
}

@keyframes background-animation {
  0%     {  background-image: linear-gradient(
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.1)
  ),
  url(./images/DesertWarzone.png);}
  50.0%  {background-image: linear-gradient(
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/ohdbackdrop2.jpeg);}
  100.0%  {background-image: linear-gradient(
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.1)
  ),
  url(./images/DesertWarzone.png);}
}


.color_orange {
  background: #FF7F00;
  color: rgba(160, 0, 0, 0.913);
  
}

.serverHeaderMain {
  display: flex;
  align-items: center;
  box-shadow:  0px 2px 1px orange;
  font-size: medium;
  color: rgb(0, 0, 0);
  border-bottom: 1px solid black;
} 

.serverHeaderMain_dark {
  display: flex;
  align-items: center;
  box-shadow:  0px 2px 1px orange;
  font-size: medium;
  color: white;
  border-bottom: 1px solid black;
} 

.serverHeader {
  display: flex;
  align-items: center;
  font-size: medium;
  color: rgb(0, 0, 0);
  border-bottom: 1px solid black;
} 


.serverValue {
  font-size:large;
  color: black;
  border-bottom: 0px solid black;
} 

.pulse {
  animation: pulse-animation 1s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}


.serverJoinLink:hover {
  cursor: crosshair;
} 

.playerHeader {
  color: rgb(0, 0, 0);
  font-size: small;
  border-bottom: 0px solid rgba(21, 255, 0, 0.531);
  text-align: justify;
  padding-top: 8px;
} 


.serverResults {
  font-size: medium;
  align-items: center;
  background-color: orange;

} 

.serverResults_dark {
  font-size: medium;
  align-items: center;

} 

.serverResults:hover {
  font-size: medium;
  align-items: center;
  color: black !important;
  box-shadow: 0px 2px 1px 0.1px rgba(207, 158, 9, 0);
  border: #000000 0px;
  background-color: #FFB347 !important;


} 

.serverResults_dark:hover {
  font-size: medium;
  align-items: center;
  color: white !important;
  box-shadow: 0px 2px 1px 0.1px orange;
} 

.serverResults_dark:hover a:visited, .serverResults_dark:hover a:link{
  color: white;
}

.serverResults:hover a:visited, .serverResults:hover a:link{
  color: black;
  background-color: rgba(255, 0, 0, 0);
}


@keyframes serverResults-intro {
  0%   { 
    transform: translateY(1000%); 		
    }
    100% { 
    transform: translateY(0%); 
    }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .serverResults, .serverResults_dark, .cardtitle, .cardtitle_dark {
    animation: fade-in 2s forwards;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .playerResults {
    animation: fade-in 1s forwards;
  }
}



@-webkit-keyframes Gradient {
  0% {
  background-position: 0% 50%
  }
50% {
background-position: 100% 50%
}
100% {
background-position: 0% 50%
}
}

@-moz-keyframes Gradient {
  0% {
  background-position: 0% 50%
  }
50% {
background-position: 100% 50%
}
100% {
background-position: 0% 50%
}
}

@keyframes Gradient {
  0% {
  background-position: 0% 50%
  }
50% {
background-position: 100% 50%
}
100% {
background-position: 0% 50%
}
}

@keyframes Colorchange {
  0% {
  background-color: rgba(255, 0, 0, 0);
  }
  10% {
    background-color: rgba(255, 0, 0, 0.2);
  }
  20% {
    background-color: rgba(255, 0, 0, 0.4);
  }
  30% {
  background-color: rgba(255, 0, 0, 0.6);
  }
  40% {
    background-color: rgba(255, 0, 0, 0.8);
  }
  50% {
    background-color: rgba(255, 0, 0, 1);
  }
  60% {
    background-color: rgba(255, 0, 0, 0.8);
  }
  70% {
    background-color: rgba(255, 0, 0, 0.6);
  }
  80% {
  background-color: rgba(255, 0, 0, 0.4);
  }
  90% {
    background-color: rgba(255, 0, 0, 0.2);
  }
  100% {
    background-color: rgba(255, 0, 0, 0);
  }


}

/* @media (prefers-reduced-motion: no-preference) {
  .connect-hover-col:hover {
    animation: pulse-animation 1s forwards;
  }
} */

/* @media (prefers-reduced-motion: no-preference) {
  .connect-hover-col:hover {
    animation: Colorchange 2s infinite;
  }
} */

.mapModal {
  color: black;
  font-family: mil;
  min-height: 500px;
  box-shadow: 4px 4px black;
} 

.mapModalHeader {
  background: rgba(255, 165, 0);
  Color: rgb(0, 0, 0);
  border: 1px solid black;
}

.mapModalBody {
  background: rgb(255, 255, 255);
  border: 1px solid black;
}

.mapModalBodyText {
  font-size: large;
  margin: 5px;
  color: rgb(255, 165, 0);
  font-size: xx-large;
  -webkit-text-stroke: 1px black;
}

.promo {
  margin: 10px;
  font-weight: bold;
  white-space: nowrap;
  color: white;
} 

.promoGetOhd {
  color: orange;
}

.promoGetOhd a:visited {
  color: orange;
  -webkit-transition: 500ms linear 0s;
  -moz-transition: 500ms linear 0s;
  -o-transition: 500ms linear 0s;
  transition: 500ms linear 0s;
  outline: 0 none;
} 

.promoGetOhd a:hover {
  color: green !important;
  -webkit-transition: 100ms linear 0s;
  -moz-transition: 100ms linear 0s;
  -o-transition: 300ms linear 0s;
  transition: 100ms linear 0s;
  outline:rgba(197, 0, 0, 1);
  font-size: larger;
} 

a:link,
a:active,
a:visited {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.902);;
  -webkit-transition: 500ms linear 0s;
  -moz-transition: 500ms linear 0s;
  -o-transition: 500ms linear 0s;
  transition: 500ms linear 0s;
  outline: 0 none;
}

a:hover {
  color: rgb(0, 0, 0);
  -webkit-transition: 100ms linear 0s;
  -moz-transition: 100ms linear 0s;
  -o-transition: 300ms linear 0s;
  transition: 100ms linear 0s;
  outline: 0 none;
}

.UndefinedMap {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/squad_opfor.png);
  background-size: cover;
  background-position: center;
}

.Argonne, .ArgonneVariety {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/Argonne.png);
  background-size: cover;
  background-position: center;
}



.PacificIsland {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/PacificIsland.png);
  background-size: cover;
  background-position: center;
}


.VoogaWinter {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/vooga_winter.png);
  background-size: cover;
  background-position: center;
}

.Vooga {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/vooga.png);
  background-size: cover;
  background-position: center;
}

.VoogaNight {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.2)
  ),
  url(./images/vooga_night.png);
  background-size: cover;
  background-position: center;
}

.Kroodu {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/kroodu.png);
  background-size: cover;
  background-position: center;
}

.KrooduNight {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.2)
  ),
  url(./images/kroodu_night.png);
  background-size: cover;
  background-position: center;
}

.KrooduWinter {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/kroodu_winter.png);
  background-size: cover;
  background-position: center;
}


.TahariDesert {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/TahariDesert.png);
  background-size: cover;
  background-position: center;
}

.OHDForest, .OHDForestInfantry, .OHDForestvehicles, .OHDForestSkirmish {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/OhdForest.png);
  background-size: cover;
  background-position: center;
}

.DesertMap {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/DesertMap.png);
  background-size: cover;
  background-position: center;
}

.SubmarineCQB {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/submarine.png);
  background-size: cover;
  background-position: center;
}

.LamDong {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/LamDong.png);
  background-size: cover;
  background-position: center;
}

.Shipment2019 {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/Shipment2019.png);
  background-size: cover;
  background-position: center;
}

.DesertFortress {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/DesertFortress.png);
  background-size: cover;
  background-position: center;
}

.NuketownSandstorm {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/Nuketown.png);
  background-size: cover;
  background-position: center;
}

.Khafji, .KhafjiVariety, .KhafjiInfantry, .KhafjiMedium {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/Khafji.png);
  background-size: cover;
  background-position: center;
}

.BorealOilfields, .BorealOilfieldsAAS {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/BorealOilfields.png);
  background-size: cover;
  background-position: center;
}


.Katerynka {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/Katerynka.png);
  background-size: cover;
  background-position: center;
}

.MonteCassino {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/MonteCassino.png);
  background-size: cover;
  background-position: center;
}

.MonteCassinoSnow {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/MonteCassinoSnow.png);
  background-size: cover;
  background-position: center;
}

.ChristmasTruce {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/ChristmasTruce.png);
  background-size: cover;
  background-position: center;
}

.UkraineDonetsk {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/Donetsk.png);
  background-size: cover;
  background-position: center;
}

.OperationLitterbox, .OperationLitterboxCTZ {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/Litterbox.png);
  background-size: cover;
  background-position: center;
}

.OperationSizzlingCaster, .OperationSizzlingCasterCTZ {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/SizzlingCaster.png);
  background-size: cover;
  background-position: center;
}

.OperationHooverville, .OperationHoovervilleCTZ {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/Hooverville.png);
  background-size: cover;
  background-position: center;
}

.OperationSpacebar, .OperationSpacebarCTZ {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/Spacebar.png);
  background-size: cover;
  background-position: center;
}

.Al-Dhakir {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/AlDhakir.png);
  background-size: cover;
  background-position: center;
}

.Grozny {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/Grozny.png);
  background-size: cover;
  background-position: center;
}

.Jaziira, .JaziiraVehicles, .JaziiraHostage, .JaziiraVehicleAASNorth, .JaziiraVehicleAASWest, .JaziiraInvasionNorth, .JaziiraVIP, .JaziiraAASFlagSpawns {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/Jaziira.png);
  background-size: cover;
  background-position: center;
}

.ColdFront {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/coldfront.png);
  background-size: cover;
  background-position: center;
}

.Airsoft {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/airsoft.png);
  background-size: cover;
  background-position: center;
}

.Stratis {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/Stratis.png);
  background-size: cover;
  background-position: center;
}

.Altis {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/Altis.png);
  background-size: cover;
  background-position: center;
}

.MoonBase {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/MoonBase.png);
  background-size: cover;
  background-position: center;
}

.Crossroads3ActiveFlags {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/Crossroads.png);
  background-size: cover;
  background-position: center;
}

.StCatherine {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/StCatherine.png);
  background-size: cover;
  background-position: center;
}

.CQBuildinglightmode {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/CQBuilding.png);
  background-size: cover;
  background-position: center;
}

.WalledCompound {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/WalledCompound.png);
  background-size: cover;
  background-position: center;
}

.swconstruction {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/sw_construction.png);
  background-size: cover;
  background-position: center;
}

.Center {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/Center.png);
  background-size: cover;
  background-position: center;
}

.Exurb {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/Exurb.png);
  background-size: cover;
  background-position: center;
}

.Europeantownww2CQB {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/europeantown.png);
  background-size: cover;
  background-position: center;
}

.Tatooine {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/Starwars_Tatooine.png);
  background-size: cover;
  background-position: center;
}

.LockerWars {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/LockerWars.png);
  background-size: cover;
  background-position: center;
}


.Stream {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/Stream.png);
  background-size: cover;
  background-position: center;
}

.Risala, .RisalaVehicles {
  background-image: linear-gradient(
    rgba(251, 251, 251, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/Risala.png);
  background-size:cover;
  background-position: center;
}

.RisalaNight {
  background-image: linear-gradient(
    rgba(251, 251, 251, 0.2),
    rgba(255, 255, 255, 0.2)
  ),
  url(./images/Risala_night.png);
  background-size:cover;
  background-position: center;
}


.SawtaloSarMountain {
  background-image: linear-gradient(
    rgba(251, 251, 251, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/SawtaloSarMountain.png);
  background-size:cover;
  background-position: center;
}

.Mogadishu, .MogadishuInfantry {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/Mogadishu.png);
  background-size: cover;
  background-position: center;
}

.Rublivka {
  background-image: linear-gradient(
    rgba(251, 251, 251, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/Rublivka.png);
  background-size:cover;
  background-position: center;
}

.TEFNoMansLand {
  background-image: linear-gradient(
    rgba(251, 251, 251, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/NoMansLand.png);
  background-size:cover;
  background-position: center;
}

.Endor {
  background-image: linear-gradient(
    rgba(251, 251, 251, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/endor.png);
  background-size:cover;
  background-position: center;
}

.TheEasternFields {
  background-image: linear-gradient(
    rgba(251, 251, 251, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/TheEasternFields.png);
  background-size:cover;
  background-position: center;
}

.TheEasternFieldsWINTER {
  background-image: linear-gradient(
    rgba(251, 251, 251, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/TheEasternFieldsWinter.png);
  background-size:cover;
  background-position: center;
}

.Trenches {
  background-image: linear-gradient(
    rgba(251, 251, 251, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/Trenches.png);
  background-size:cover;
  background-position: center;
}

.TrenchesAndFields {
  background-image: linear-gradient(
    rgba(251, 251, 251, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/TrenchesAndFields.png);
  background-size:cover;
  background-position: center;
}

.TestMapAAS {
  background-image: linear-gradient(
    rgba(251, 251, 251, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/Testmap.png);
  background-size:cover;
  background-position: center;
}

.BRUTAL {
  background-image: linear-gradient(
    rgba(251, 251, 251, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/Brutal.png);
  background-size:cover;
  background-position: center;
}

.Bridge {
  background-image: linear-gradient(
    rgba(251, 251, 251, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/Bridge.png);
  background-size:cover;
  background-position: center;
}

.BlueRidgeInfantry, .BlueRidgeLargeAAS {
  background-image: linear-gradient(
    rgba(251, 251, 251, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/blueridge.png);
  background-size:cover;
  background-position: center;
}

.Voogavehicles, .Vooga, .Voogasmall, .VoogavehicleHABS {
  background-image: linear-gradient(
    rgba(251, 251, 251, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/vooga.png);
  background-size:cover;
  background-position: center;
}

.Isyan {
  background-image: linear-gradient(
    rgba(251, 251, 251, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/isyan.png);
  background-size:cover;
  background-position: center;
}

.OperationRazzorblade, .RazzorbladeHAB, .Razzorblade1 {
  background-image: linear-gradient(
    rgba(251, 251, 251, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/razzorblade.png);
  background-size:cover;
  background-position: center;
}

.OperationLeanSteepMiddle, .OperationLeanSteepHABS, .OperationLeanSweepHABS, .OperationLeanSteepBf {
  background-image: linear-gradient(
    rgba(251, 251, 251, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/leansteep.png);
  background-size:cover;
  background-position: center;
}

.TatarWetlands32, .TatarWetlands16, .TatarWetlands64, .TatarWetlandsmiddle, .TatarWetlandshab {
  background-image: linear-gradient(
    rgba(251, 251, 251, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/tatarwetlands.png);
  background-size:cover;
  background-position: center;
}

.Volcano {
  background-image: linear-gradient(
    rgba(251, 251, 251, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/Volcano.png);
  background-size:cover;
  background-position: center;
}

.AtollAAS {
  background-image: linear-gradient(
    rgba(251, 251, 251, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/Atoll.png);
  background-size:cover;
  background-position: center;
}

.MilitaryBase {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/MilitaryBase.png);
  background-size: cover;
  background-position: center;
}

.BunkerBattle {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/BunkerBattle.png);
  background-size: cover;
  background-position: center;
}

.DesertWarzone {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ),
  url(./images/DesertWarzone.png);
  background-size: cover;
  background-position: center;
}

.JungleRun {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  ),
  url(./images/JungleRun.png);
  background-size: cover;
  background-position: center;
}

.CityPark {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  ),
  url(./images/CityPark.png);
  background-size: cover;
  background-position: center;

}

.District {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  ),
  url(./images/District.png);
  background-size: cover;
  background-position: center;

}

.Metro {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  ),
  url(./images/Metro.png);
  background-size: cover;
  background-position: center;
}

.Trainyard {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  ),
  url(./images/Trainyard.png);
  background-size: cover;
  background-position: center;

}

.DesertCompound {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  ),
  url(./images/DesertCompound.png);
  background-size: cover;
  background-position: center;
}

.button_slide {
  padding: 2px 10px;
  display: inline-block;
  letter-spacing: 2px;
  cursor: crosshair;
  -webkit-transition: ease-out 0.1s;
  -moz-transition: ease-out 0.1s;
  transition: ease-out 0.1s;
  border-radius: 1px 1px;
  align-items: center;
}

.button_slide_dark {
  padding: 2px 10px;
  display: inline-block;
  letter-spacing: 2px;
  cursor: crosshair;
  -webkit-transition: ease-out 0.1s;
  -moz-transition: ease-out 0.1s;
  transition: ease-out 0.1s;
  border-radius: 1px 1px;
  align-items: center;
}

.slide_down:hover {
  box-shadow: inset 0 100px 0 0 rgba(255, 165, 0, 0.9);
  border-bottom: 2px solid black;
}

.slide_right:hover {
  box-shadow: 0px 1px 1px 3px #ff0015b2;
  border: #000000;
}

.slide_right_dark:hover {
  color: rgb(255, 255, 255) !important;
  box-shadow: 0px 1px 1px 3px #ff0015b2;
  border: #000000;
}

.slide_left:hover {
  box-shadow: inset 0 0 0 50px rgba(255, 165, 0, 0.9);
  font-size: larger;
  border-bottom: 2px solid black;
}

.slide_diagonal:hover {
  box-shadow: 10px 50px 0 0 #ffa500e6;
  border-bottom: 9px solid black;
}

.globalstats {
  background: rgba(255, 255, 255, 0.4);
  font-size: 100px;
  color: red;
  min-height: 150px;
  text-align: center;
  border: 1px solid black;
  -webkit-text-stroke: 2px black;
}

.globalstatsheader {
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid black;
  font-size: auto;
  text-align: left;
  font-size: x-large;
  -webkit-text-stroke: 0.1px rgba(0, 0, 0, 1);
  padding-left: 10px;
}

.apexcharts-tooltip {
  background: rgba(255, 165, 0, 0.9);
  color: black;
}

.block .hover {
  display: none;
}

.block .connect-hover {
  display: none;
}

.block:hover .hover {
  display: inline;
  cursor: crosshair;
}

.block:hover .connect-hover {
  display: inline;
  cursor: crosshair;
}

.connect-hover-col:hover {
  /* background-color: rgba(255, 0, 0, 0.8);
  color: white; */
  font-weight: 500;
}

.cardheaderinfo {
  color: rgba(0, 0, 0, 0);
  text-align: center;
}

.cardheaderinfo:hover {
  color: black;
  text-align: center;
  font-weight: 400;
}

.cardbodyinfo {
  color: rgba(0, 0, 0, 0);
  text-align: center;
}

.cardbodyinfo:hover {
  color: black;  
  text-align: center;
  font-weight: 400;
  background-color: rgba(128, 128, 128, 0);
}

.crosshair {
  cursor: crosshair;
}

.crosshair_dark {
  opacity: 1;
  text-shadow: 1px 1px 2px darkgreen, 0 0 25px darkgreen, 0 0 5px darkgreen;
}

.crosshair_light {
  opacity: 1;
}

@keyframes gt100-pulsate {
  0% {
    text-shadow: -1px 1px 1px rgb(3, 157, 72, 0.3), 1px -1px 1px rgb(4, 179, 4, 0.5);
  }
  50% {
    text-shadow: -1px 1px 10px rgb(3, 157, 72), 1px -1px 10px rgb(4, 179, 4);
  }
  100% {
    text-shadow: -1px 1px 1px rgb(3, 157, 72, 0.3), 1px -1px 1px rgb(4, 179, 4, 0.4);
  }
}

@keyframes gt200-pulsate {
  0% {
    text-shadow: -1px 1px 1px rgb(0, 188, 235, 0.4), 1px -1px 1px rgb(0, 188, 235, 0.5);
  }
  50% {
    text-shadow: -1px 1px 10px rgb(0, 188, 235), 1px -1px 10px rgb(0, 188, 235);
  }
  100% {
    text-shadow: -1px 1px 1px rgb(0, 188, 235, 0.5), 1px -1px 1px rgb(0, 188, 235, 0.4);
  }
}

.test {
  color: #00000080
}

@keyframes gt400-pulsate {
  0% {
    text-shadow: -1px 1px 1px rgb(116, 3, 157, 0.4), 1px -1px 1px rgb(116, 3, 157, 0.3);
  }
  50% {
    text-shadow: -1px 1px 10px rgb(116, 3, 157), 1px -1px 10px rgb(195, 0, 255);
  }
  100% {
    text-shadow: -1px 1px 1px rgb(116, 3, 157, 0.5), 1px -1px 1px rgb(141, 0, 211, 0.4);
  }
}


@media (prefers-reduced-motion: no-preference) {
  .gt200 {
    animation: gt200-pulsate infinite 2s forwards;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .gt400 {
    animation: gt400-pulsate infinite 2s forwards;
  }
}

.playerHighscore {
  text-shadow: 1px 2px 5px purple;
}

.botimages img {
  opacity: 0.5;
}

/* .botimages:hover img {
  transform: scale(1.2);
  opacity: 0.5;
} */

.codestyle {
  color: rgb(255, 0, 0);
}

.ohdbg_serverresults .input-group .btn>a {
  color: orange;
}

.ohdbg_serverresults .input-group .btn>a:hover {
  color: limegreen !important;
}

.ohdbg_serverresults .input-group .btn>a:visited {
  color: orange;
}

@keyframes freeohd-pulsate {
  0% {
    text-shadow: -1px 1px 20px rgba(248, 247, 247, 0.6), 1px -1px 1px rgba(255, 255, 255, 0.6);
  }
  50% {
    text-shadow: -1px 1px 0px rgba(0, 255, 30, 0), 1px -1px 10px rgba(48, 238, 0, 0);
  }
  100% {
    text-shadow: -1px 1px 20px rgba(255, 255, 255, 0.6), 1px -1px 1px rgba(255, 255, 255, 0.6);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .ohdbg_serverresults .input-group .btn>a {
    animation: freeohd-pulsate infinite 4s forwards;
  }
}

.discordOn {
  color: #5865F2;
  padding-left: 0px;
}

.twitchOn {
  color: purple;
}

.twitchOn:hover {
  color: red;
  padding-left: 0px;
}

.kickOn {
  color: greenyellow;
}

.kickOff:hover {
  color: black;
  padding-left: 0px;
}


.twitchOff {
  color: 4e4e50;
}



.discordOn:hover {
  color: rgb(255, 0, 0);
  padding-left: 0px;
}


.discordOff {
  color: #4e4e50;
  padding-left: 1px;
}

.unmodded, .unselected {
  color: #4e4e50;
  padding-left: 0px;
}

.modded, .selected {
  color: rgb(255, 0, 0);
  padding-left: 1px;
}

.navbarhoover:hover {
 
  color:orange !important;
}

.videoFooter{
  font-size: small;
  align-self: center;
  border: #000000 1px solid;
}

.modcardFooter{
  text-align: center;
}


.emptyServer{
  color: black;
}
.emptyServer_dark, .emptyServer_dark a:visited {
  color: grey;
}

.emptyServer_dark a:hover {
  color: white;
}

.ticker {
  background: black;
  font-size:large;
  border-top: 1px solid #000000;
  margin: 0px;
  min-height: 30px;
}

.moddownloader {
  background-color: lightgray;
  max-width: 50%;
  border: black 1px solid;
  text-align: left;
  padding-left: 5px;
}

.cardtitle {
    font-size: smaller;
  border-radius: 0px;
  border-color: black;
  margin-top: 10px;
  margin-left: 2px;
  box-shadow: 2px 3px 2px darkslategrey;
}

.cardtitle_light {
font-size: smaller;
border-radius: 0px;
border-color: black;
margin-top: 10px;
margin-left: 2px;
box-shadow: 2px 3px 2px goldenrod;
}

.cardbody {
border-color: black;
min-height: 70px;
}

.cardbody_dark {
  border-color: black;
  min-height: 70px;
}


.cardtitle_dark {
    font-size: smaller;
  border-radius: 1px;
  border-color: black;
  margin-top: 10px;
  margin-left: 2px;
  box-shadow: 2px 3px 2px darkslategray;
}

.cardtitleicons {
  text-align: center;
}

/* .cardheader:hover {
  background: radial-gradient(ellipse farthest-corner at left bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
  radial-gradient(ellipse farthest-corner at right top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
} */

.cardheader:hover {
  background: rgba(231, 231, 231, 0.8);
}

.cardheader_dark:hover {
  background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
  radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
}

.cardbody:hover {
  background: rgba(231, 231, 231, 0.8);
}

.cardbody_dark:hover {
  background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
  radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
}

.golden1 {
  background-image: -webkit-linear-gradient(#FFF65C, #3A2C00);
  text-shadow: -0.02em -0.03em 0.005em rgba(255, 223, 0, 0.60);
}
.golden2 {
  background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
                radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
  font-size: larger;
  font-weight: bold;
}

.modcardbody {
  min-height: 200px;
  max-height: 200px;
}

.center-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}
